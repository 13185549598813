.footer {
    text-align: center;
    color: #888;
    padding: 20px;
    margin: 0;
    height: 20px;
}

.footerLink {
    color: #888;
}